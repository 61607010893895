/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';
// import Helmet from 'react-helmet';
// eslint-disable-next-line import/no-extraneous-dependencies
import { stringify } from 'qs';
import { serialize } from 'dom-form-serializer';

import './Form.css';

class Form extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    name: 'mechanician_contact_form',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Thanks for your enquiry, we will get back to you soon',
    errorMessage:
      'There is a problem, your message has not been sent, please try contacting us via email',
  }

  state = {
    alert: '',
    disabled: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.disabled) return;

    const form = e.target;
    const data = serialize(form);
    this.setState({ disabled: true });
    fetch(`${form.action}?${stringify(data)}`, {
      method: 'POST',
    })
      .then((res) => {
        if (res.ok) {
          return res;
        }
        throw new Error('Network error');
      })
      .then(() => {
        form.reset();
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.setState({
          disabled: false,
          alert: this.props.errorMessage,
        });
      });
  }

  render() {
    const { name, subject, action } = this.props;

    return (
      <>
        {/* <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Helmet> */}
        <form
          className="Form"
          name={name}
          action={action}
          netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          data-netlify="true"
        >
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
          <div className="Form--Group">
            <p className="ohnohoney">
              <label>
                Don’t fill this out if you're human:
                {' '}
                <input name="bot-field" />
              </label>
            </p>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Firstname"
                name="firstname"
                required
              />
              <span>Firstname</span>
            </label>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Lastname"
                name="lastname"
                required
              />
              <span>Lastname</span>
            </label>
          </div>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
            />
            <span>Email address</span>
          </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Message"
              name="message"
              rows="10"
              required
            />
            <span>Message</span>
          </label>
          {/* <div
            className="g-recaptcha"
            data-sitekey="6LfKN3kUAAAAAGIM1CbXmaRZx3LIh_W2twn1tzkA"
          /> */}
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Enquire"
            disabled={this.state.disabled}
          />
        </form>
      </>
    );
  }
}

export default Form;
